.pageContent {
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  height: 100%;
}

.googleMaps {
  width: 100%;
  height: 100%;
}

#infoRow {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

#weather {
  width: 50%;
}

#systemSummary {
  padding-left: 30px;
  width: 50%;
}