.Navigation {
  height: 100vh;
  width: 150px;
  min-width: 150px;
  background-color: #2F4050;
}

.NavigationList {
  height: auto;
  padding: 0;
  margin: 0;
  width: 100%;
}

.NavigationList .NavigationRow {
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.NavigationList .NavigationRow:hover {
  cursor: pointer;
  background-color: #293846;
}

.NavigationRow #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.NavigationRow #title {
  flex: 70%;
}

.NavigationList #active {
  background-color: #293846;
}